import { ticketActions } from "../../types/types";
import unset from "lodash/unset";

const initialStatus = {
  newTickets: {},
  newUpdates: {}
};

export default (state = initialStatus, action) => {
  switch (action.type) {
    case ticketActions.TICKET_NEW:
      return {
        ...state,
        newTickets: {
          ...state.newTickets,
          [action.ticket.ticket_number]: true
        },
        newUpdates: {
          ...state.newUpdates,
          [action.ticket.ticket_number]: action.ticket
        }
      };

    case ticketActions.TICKET_RECIEVED:
      unset(state, `newUpdates.${action.ticketNumber}`);
      return {
        ...state
      };

    case ticketActions.TICKET_VIEWED:
      const ticketNumber = action.ticketNumber || action.ticket.ticket_number;
      unset(state, `newTickets.${ticketNumber}`);
      unset(state, `newUpdates.${ticketNumber}`);
      return {
        ...state
      };

    default:
      return state;
  }
};
