import { configurations } from "../../types/types";

let menuConfig = localStorage.getItem("menuConfig");
let sideMenuStateData = localStorage.getItem("sideMenuState");
if (menuConfig) {
  menuConfig = JSON.parse(menuConfig);
}

const initialStatus = {
  config: menuConfig ? menuConfig : {},
  sideMenuState: sideMenuStateData ? JSON.parse(sideMenuStateData) : false,
  updateItemCode: ""
};

const configuration = (state = initialStatus, action) => {
  switch (action.type) {
    case configurations.CONFIGURATION_DETAILS:
      localStorage.setItem("menuConfig", JSON.stringify(action.data));
      return { ...initialStatus, config: action.data };
    case configurations.UPDATE_SIDEBAR_STATE:
      localStorage.setItem("sideMenuState", JSON.parse(action.data));
      return { ...initialStatus, sideMenuState: JSON.parse(action.data) };
    case configurations.UPDATE_MENU_CODE:
      return { ...initialStatus, updateItemCode: action.data };
    default:
      return state;
  }
};

export default configuration;
