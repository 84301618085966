import { userActions } from "../../types/types";
let auth = localStorage.getItem("auth");
let rememberMe = localStorage.getItem("rememberMe");

if (auth) {
  auth = JSON.parse(auth);
}
const initialStatus = {
  requesting: false,
  isAuthenticated: auth ? true : false,
  token: auth ? auth.token : false,
  hasAuthError: false,
  errors: false,
  loading: false,
  details: auth ? auth.result : {},
  rememberMe: rememberMe ? Boolean(rememberMe) : false,
  permissions: [],
  apiError: {},
  menus:[]
};

let permissionData = [
  { name: "add_userprofilemodel" },
  { name: "change_userprofilemodel" },
  { name: "delete_userprofilemodel" },
  { name: "view_userprofilemodel" }
];

const user = (state = initialStatus, action) => {
  switch (action.type) {
    case userActions.AUTH_REQUEST:
      return {
        ...initialStatus,
        isAuthenticated: false,
        token: false,
        requesting: true
      };

    case userActions.AUTH_LOGIN:
      return {
        ...initialStatus,
        isAuthenticated: true,
        token: action.payload.token,
        details: action.payload.details,
        permissions: permissionData
      };


    case userActions.INVALID_LOGIN:
      return {
        ...initialStatus,
        hasError: true,
        isAuthenticated: false,
        errors: action.payload.display
      };

    case userActions.HANDLE_SIGN_OUT:
      return { ...initialStatus, isAuthenticated: false, token: false };

    case userActions.HANDLE_API_ERROR:
      return { ...state, apiError: action.payload };

    case userActions.USER_DATA_LOADING:
      return { ...initialStatus, loading: action.isLoading };

    case userActions.REMEMBER_ME:
      localStorage.setItem("rememberMe", !state.rememberMe);
      return { ...state, rememberMe: !state.rememberMe };

    case userActions.MENUS:
      return {
        ...state,
        menus:action.payload.menus
      }

    default:
      return state;
  }
};

export default user;
