import moment from "moment";
export const parseColumnValue = (value, parseType) => {
      switch (parseType) {
        case "date":
          return moment(value).format("DD-MM-YYYY");
        case "currency":
          return value + " Rs";
        default:
          return value;
      }
  };