import { locationActions } from "../../types/types";
const initialStatus = {
  isValid: false,
  name: false,
  locationNotFound: false
};

const restaurantLocation = (state = initialStatus, { type, payload }) => {
  switch (type) {
    case locationActions.HANDLE_VALID_LOCATION:
      localStorage.setItem("location_name", payload.name);
      return { isValid: true, name: payload.name };
    case locationActions.HANDLE_INVALID_LOCATION:
      return { isValid: false, name: payload.name, locationNotFound: true };
    default:
      return state;
  }
};

export default restaurantLocation;
