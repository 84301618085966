// auth user types
export const userActions = {
  AUTH_REQUEST: "AUTH_REQUEST",
  AUTH_LOGIN: "AUTH_LOGIN",
  INVALID_LOGIN: "INVALID_LOGIN",
  HANDLE_SIGN_OUT: "HANDLE_SIGN_OUT",
  HANDEL_DEPARTMENT_CREATE: "HANDEL_DEPARTMENT_CREATE",
  USER_DATA_LOADING: "USER_DATA_LOADING",
  REMEMBER_ME: "REMEMBER_ME",
  HANDLE_API_ERROR: "HANDLE_API_ERROR",
  MENUS:"MENUS",
  IS_EDIT:"IS_EDIT"
};

export const configurations = {
  CONFIGURATION_DETAILS: "CONFIGURATION_DETAILS",
  UPDATE_SIDEBAR_STATE: "UPDATE_SIDEBAR_STATE",
  UPDATE_MENU_CODE: "UPDATE_MENU_CODE"
};

// organization action types
export const locationActions = {
  HANDLE_VALID_LOCATION: "HANDLE_VALID_LOCATION",
  HANDLE_INVALID_LOCATION: "HANDLE_INVALID_LOCATION"
};

// table action types
export const settingActions = {
  CONF_REQUEST: "CONF_REQUEST",
  CONF_ERROR: "CONF_ERROR",
  CONF_REQUEST_COMPLETE: "CONF_REQUEST_COMPLETE",
  CONF_REGISTER_MODULE: "CONF_REGISTER_MODULE",
  CONF_HANDLE_CHANGE: "CONF_HANDLE_CHANGE",
  CONF_REQUEST_CLEAR_FORM:"CONF_REQUEST_CLEAR_FORM"
};

export const tableActions = {
  TBL_REQUEST_DATA: "TBL_REQUEST_DATA",
  TBL_ERROR: "TBL_ERROR",
  TBL_REQUEST_COMPLETE: "TBL_REQUEST_COMPLETE",
  TBL_REGISTER_TABLE: "TBL_REGISTER_TABLE",
  TBL_REMOVE_RECORD: "TBL_REMOVE_RECORD",
  TBL_REMOVE_RECORD_COMPLETE: "TBL_REMOVE_RECORD_COMPLETE",
  TBL_RELOAD: "TBL_RELOAD"
};

export const fetchActions = {
  FETCH_REQUEST: "FETCH_REQUEST",
  FETCH_ERROR: "FETCH_ERROR",
  FETCH_COMPLETE: "FETCH_COMPLETE",
  FETCH_PUSH_ON_TOP: "FETCH_PUSH_ON_TOP",
  FETCH_POP_OUT: "FETCH_POP_OUT",
  FETCH_TICKET_UPDATE:"FETCH_TICKET_UPDATE"
};

export const ticketActions = {
  TICKET_NEW: "TICKET_NEW",
  TICKET_RECIEVED: "TICKET_RECIEVED",
  TICKET_VIEWED: "TICKET_VIEWED"
};

// Internet Connection
export const checkInternetConnection = {
  CHECK_INTERNET: "CHECK_INTERNET"
}