import { fetchActions } from "../../types/types";
import findKey from "lodash/findKey";

const initialState = {
  requesting: false,
  hasError: false,
  error: null,
  data: []
};

const markNoErrors = {
  requesting: false,
  hasError: false,
  error: null
};

const fetch = (state = {}, action) => {
  switch (action.type) {
    case fetchActions.FETCH_REQUEST:
      return {
        ...state,
        [action.requestName]: { ...initialState, requesting: true }
      };

    case fetchActions.FETCH_ERROR:
      return {
        ...state,
        [action.requestName]: {
          ...state[action.requestName],
          ...markNoErrors,
          hasError: true,
          error: action.error
        }
      };

    case fetchActions.FETCH_COMPLETE:
      return {
        ...state,
        [action.requestName]: {
          ...state[action.requestName],
          ...markNoErrors,
          data: action.payload,
          count: action.count
        }
      };

    case fetchActions.FETCH_TICKET_UPDATE:
      return{
        ...state,
        [action.name]: {
            ...action.payload
        }
      }

    case fetchActions.FETCH_PUSH_ON_TOP:
      let data = state[action.requestName].data || [];
      if(Object.keys(data).length >0){
        const key = findKey(data, {
          ticket_number: action.data.ticket_number
        });
        if (key !== undefined) {
          data[key] = action.data;
        } else {
          data = [action.data, ...data];
        }
      }
     

      return {
        ...state,
        [action.requestName]: {
          ...state[action.requestName],
          data: Object.keys(data).length >0?[...data]:{}
        }
      };

    case fetchActions.FETCH_POP_OUT:
      let previousData = state[action.requestName].data || [];
      const deleteKey = findKey(previousData, {
        ticket_number: action.data.ticket_number
      });

      if (deleteKey !== undefined) {
        delete previousData[deleteKey];
      }

      return {
        ...state,
        [action.requestName]: {
          ...state[action.requestName],
          data: [...previousData]
        }
      };
    default:
      return state;
  }
};

export default fetch;
